import { Navigate, RouteObject } from "react-router-dom";
import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import PatientListPage from "../pages/patients/PatientListPage";
import PatientDetailPage from "../pages/patients/PatientDetailPage";
import SettingsPage from "../pages/settings/SettingsPage";

export const routes: RouteObject[] = [
  { path: "/", element: <LoginPage /> },
  { path: "/home", element: <HomePage /> },
  { path: "/patients", element: <PatientListPage /> },
  { path: "/patients/:id", element: <PatientDetailPage /> },
  { path: "/settings", element: <SettingsPage /> },

  { path: "*", element: <Navigate to="/home" replace /> },
];
