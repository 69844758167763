import { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { Button, Container, Text, Title } from "@mantine/core";

export default function HomePage() {
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserName(user.displayName || "");
      } else {
        navigate("/", { replace: true }); // if logged out manually or token expired
      }
    });
    return () => unsub();
  }, [navigate]);

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/", { replace: true });
  };

  return (
    <Container>
      <Title order={2} mt="md">
        Welcome back, {userName}!
      </Title>
      <Text c="dimmed" mb="lg">
        You're logged in and ready to go.
      </Text>
      <Button color="red" onClick={handleLogout}>
        Logout
      </Button>
    </Container>
  );
}
