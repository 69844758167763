import { useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../firebase";
import { Navigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import LoginGif from "../../assets/login-illustration.gif";
import GoogleLogo from "../../assets/logos/google.svg";


import {
  Button,
  Paper,
  Text,
  Container,
  Image,
  Stack,
  Center,
} from "@mantine/core";

export default function LoginPage() {
  const [user, setUser] = useState<any>(null);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(setUser);
    return () => unsub();
  }, []);

  const loginWithGoogle = async () => {
    await signInWithPopup(auth, new GoogleAuthProvider());
  };

  if (user) {
    return <Navigate to="/home" replace />;
  }

  return (
    <Container
      size="lg"
      fluid
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem",
        height: "100vh",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {/* Left side - image */}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: isMobile ? "1rem" : "2rem",
        }}
      >
        <Image
          src={LoginGif}
          alt="login illustration"
          fit="contain"
          style={{
            width: isMobile ? "80%" : "100%",
            maxWidth: "500px",
            height: "auto",
          }}
        />
      </div>
      {/* Right side - login box */}
      <div style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMobile ? "1rem" : "2rem",
      }}>

        <Paper
          radius="md"
          p="xl"
          withBorder
          style={{ width: "100%", maxWidth: 400 }}
        >


          <Stack>
            <Text size="xl" fw={700} mb={0}>
              Hi, from FriendApp!
            <Text c="dimmed" size="sm" mt={0}>
              Great to have you here!
            </Text>
            </Text>

            {/* <TextInput label="Email" placeholder="john.doe@gmail.com" />
            <PasswordInput label="Password" placeholder="Your password" />

            <Checkbox label="I agree to privacy policy & terms" />

            <Button fullWidth mt="md" disabled>
              Sign up
            </Button>

            <Text c="dimmed" size="sm" ta="center">
              Already have an account? Sign in instead
            </Text>

            <Divider label="or" labelPosition="center" /> */}

            <Center>
              <Button
                variant="default"
                onClick={loginWithGoogle}
                fullWidth
                leftSection={
                  <img src={GoogleLogo} alt="Google" style={{ height: 18, width: 18 }} />
                }
              >
                Sign in with Google
              </Button>
            </Center>
          </Stack>
        </Paper>
      </div>
    </Container>
  );
}
